<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="copyOrderForm"
        @submit.prevent="submitCopyOrderForm"
      >
        <v-row v-if="dataFetched">
          <v-col
            cols="6"
            class="pt-0 mt-n2"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <ChooseClientAddressPartial
                  ref="chooseClientAddressPartial"
                  :addresses="addresses"
                  :order-data="order"
                  :course-type="course.courseType"
                  :show-address-form="showAddressForm"
                  :specific-client="true"
                  :distance="straightLineDistance"
                  :is-editing-order="true"
                  @update="updateFormValue"
                  @addAddress="changeAddressFormStatus(true)"
                  @editAddress="changeAddressFormStatus(true, true)"
                />
              </v-col>

              <template v-if="showAddressForm">
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <div class="form__section-title">
                    {{ chosenAddress? 'Edytuj' : 'Dodaj' }} lokalizację
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  class="p-0"
                >
                  <AddressForm
                    actions
                    :editing-address="chosenAddress"
                    :forced-department="department.id"
                    :edit-address-products="true"
                    @close="changeAddressFormStatus(false)"
                    @addNewAddress="addAddress"
                    @editAddress="updateAddress"
                  />
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="pt-0 mt-4"
          >
            <v-row>
              <v-col cols="12">
                <NewCoursePartial
                  :course-data="course"
                  :order-data="order"
                  is-simple-order-type
                  @update="updateFormValue"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <NewPaymentPartial
                  ref="payment"
                  :payment-data="payment"
                  :course-type="course.courseType"
                  :client-type="clientType"
                  :aggregate-amount="order.aggregateAmount"
                  @update="updateFormValue"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        :disabled="showAddressForm"
        @click="submitCopyOrderForm()"
      >
        Stwórz zlecenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit } from '../../utils'
import updateFormValue from '../../mixins/updateFormValue.vue'
import orderFormMixin from '../../mixins/orderFormMixin.vue'
import { NewOrder, Course } from '../../models'
import DateTime from 'luxon/src/datetime'

export default {
  mixins: [
    updateFormValue,
    orderFormMixin, // changeAddressFormStatus, fetchClientAddresses, addAddress, updateAddress,
    // order, course, payment, showAddressForm, , chosenAddress, locationDiscount ,destinationCoordinates,
    // department, isProcessing, straightLineDistance, closeDialog, showSnackbar, clientType
    // watch: 'order.addressId'
  ],
  data () {
    return {
      dataFetched: false
    }
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      baseOrder: state => state.order.entity,
    }),
  },
  created () {
    const { client, id } = new Course(this.dialog.item).order
    const getOrder = this.getOrder({ id })
    const getClient = this.getClient(client.id)

    return Promise.all([getOrder, getClient])
      .then(async () => {
        const { order, payment, course } = new NewOrder(this.baseOrder)
        this.order = order
        this.payment = payment
        this.course = course
        this.clientType = this.baseOrder.client.clientType
        const today = DateTime.now().startOf('day')
        const dueDate = DateTime.fromISO(this.course.dueDate).startOf('day')
        if (+dueDate < +today) {
          this.course.dueDate = today.toISODate()
        }
        if (order.middlemanId) {
          await this.getClient(order.middlemanId)
        }
        this.fetchClientAddresses()
          .then(() => {
            this.dataFetched = true
          })
      })
  },
  beforeDestroy () {
    this.clearSingleOrder()
  },
  methods: {
    ...mapActions({
      addNewOrder: 'orders/addNewItem',
      getOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      getClient: 'client/getSingleClient'
    }),
    submitCopyOrderForm () {
      if (this.$refs.copyOrderForm.validate()) {
        const payment = { ...this.payment }
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.transportNetValue = parseAsBasicUnit(payment.transportNetValue)
        payment.transportGrossValue = parseAsBasicUnit(payment.transportGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)
        const params = {
          ...this.order,
          course: this.course,
          payment
        }
        if (this.course.courseType === 'Podstawienie') delete params.aggregateAmount

        this.addNewOrder({ params, endpoint: 'orders' })
          .then(() => {
            this.$refs.copyOrderForm.reset()
            this.closeDialog()
          })
      } else {
        this.showSnackbar({
          type: 'error',
          message: ['Nie wszystkie pola wypełniono poprawnie']
        })
      }
    }
  }
}
</script>
